.pageBackground {
  background-image: url('https://hophacks-image.s3.us-east-1.amazonaws.com/hophacks_title_layers.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-left: 28rem;
  margin-top: 10rem;
}

.headerRow:first-of-type {
  margin-bottom: 0rem;
}

.headerRow {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.textLine {
  font-family: Anton, sans-serif;
  font-weight: 400;
  font-size: 140px;
  line-height: 120px;
  color: #ffffff;
  text-transform: uppercase;
}

.hophacks-logo {
  width: 200px;
  height: auto;
  margin-bottom: -2rem;
}

.larger-letter {
  font-size: 1.1em; /* Slightly larger than the parent */
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  text-transform: inherit;
}

.textSubtext {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 43px;
  color: #ffe188;
  text-transform: uppercase;
}

.details {
  font-family:
    Open Sans,
    sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #ffffff;
}

.discordbtnContainer {
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.join-discord {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  transition: all 300ms ease-in-out;
  text-shadow: 0 0 8px #facc15;
  border: none;
  cursor: pointer;
  background: transparent;
  color: white;
  font-size: 2rem;
}

.join-discord:hover {
  text-shadow: 0 0 36px #facc15;
}

/* Responsive */

@media (max-width: 1200px) {
  .mainContent {
    margin-left: 20rem;
    margin-top: 10rem;
  }

  .textLine {
    font-size: 100px;
    line-height: 100px;
  }

  .hophacks-logo {
    width: 150px;
  }

  .join-discord {
    font-size: 1.5rem;
  }

  .textSubtext {
    font-size: 36px;
  }

  .details {
    font-size: 24px;
  }
}

@media (max-width: 950px) {
  .mainContent {
    margin-left: 2rem;
    margin-top: 3rem;
  }

  .textLine {
    font-size: 64px;
    line-height: 70px;
  }

  .hophacks-logo {
    width: 90px;
    margin-bottom: -1rem;
  }

  .join-discord {
    font-size: 1.25rem;
    margin-bottom: 0;
  }

  .textSubtext {
    font-size: 26px;
  }

  .details {
    font-size: 18px;
  }
}
